import * as Base from "Everlaw/Base";
import * as Rest from "Everlaw/Rest";
import { OrganizationId } from "Everlaw/MinimalOrganization";

/**
 * Represents a user identifier.
 * @constructor
 */
export class OrgLabel extends Base.Object {
    get className() {
        return "OrgLabel";
    }
    override id: OrgLabelId;
    identifier: string;
    abbreviation: string;
    orgId: number;

    constructor(params: any) {
        super(params);
        this._mixin(params);
    }

    override _mixin(params: any) {
        Object.assign(this, params); // take them all
    }

    override display() {
        return this.identifier;
    }
}

type OrgLabelId = number & Base.Id<"OrgLabel">;

if (JSP_PARAMS.OrgLabel) {
    Base.set(OrgLabel, JSP_PARAMS.OrgLabel);
}

/**
 * Retrieve from {@link Base} user identifier by name. Used to check
 * for duplicate names within an organization.
 *
 * @param name Name of identifier to retrieve
 * @param orgId Organization ID to verify duplicate names are within the same org
 */
export function findLabelByName(name: string, orgId: number): OrgLabel | undefined {
    return Base.get(OrgLabel).find(
        (label) => label.orgId === orgId && label.identifier === name?.trim(),
    );
}

/**
 * Create a new user identifier and add to {@link Base}.
 *
 * @async
 * @param orgId Organization ID
 * @param identifier New user identifier name
 * @param abbreviation New user identifier abbreviation
 */
export async function createOrgLabel(
    orgId: OrganizationId,
    identifier: string,
    abbreviation: string,
): Promise<void> {
    const data = await Rest.post("/newOrgLabel.rest", {
        identifier,
        abbreviation,
        orgId,
    });
    Base.set(OrgLabel, data);
}

/**
 * Update a user identifier.
 *
 * @async
 * @param id ID of OrgLabel to update in {@link Base}
 * @param updatedProperties New name or abbreviation to update
 */
export async function updateOrgLabel(
    id: OrgLabelId,
    updatedProperties: { identifier?: string; abbreviation?: string },
): Promise<void> {
    const data = await Rest.post("/updateOrgLabel.rest", {
        id,
        ...updatedProperties,
    });
    Base.update(OrgLabel, data);
}

/**
 * Remove a user identifier.
 *
 * @async
 * @param label OrgLabel to remove in {@link Base}
 */
export async function removeOrgLabel(label: OrgLabel): Promise<void> {
    await Rest.post("/deleteOrgLabel.rest", {
        id: label.id,
    });
    Base.remove(label);
}
